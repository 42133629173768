import './App.css'
import tosuto from './assets/tosuto.png'
import {HalftoningPost} from "./halftoning";
import {TosutoPost} from "./tosuto";
import {WelcomePost} from "./welcome";
import {WipPost} from "./wip";
import {DesmosPost} from "./desmos";

function App() {
  return (
    <div>
      <WelcomePost/>
      {DesmosPost}
      {WipPost}
      {TosutoPost}
      {HalftoningPost}
    </div>
  )
}

export default App
