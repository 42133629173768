import {CodeBlock, InlineCode, Link, Post, Subtitle} from "./Post";
import desmos from "./assets/desmos.png";
import cube from "./assets/cube.png"

export const DesmosPost = (
  <Post imgSrc={desmos} imgAlt={"desmos image"} imgCover={true}
        title={"a 3d renderer in 2d desmos"} date={"may 11, 2024"}
        id={"desmos"}>
    <p>
      This project started seven months ago, when my precalc teacher told us
      about his brilliant assignment: make an artistic graph in the Desmos
      Graphing calculator. I knew I wanted to make something in 3D, but I didn't
      get it working in time, so I ended up making this <Link
      link={"https://www.desmos.com/calculator/5pgpochvby"}>monstrosity</Link>.
    </p>
    <br/>
    <p>
      About a month ago, he had us make another graph, and I knew I wanted to
      one-up myself. I thought back to that 3D renderer I tried to implement but
      failed to get working, and tried to fix it up!
    </p>
    <br/>
    <p>
      <Link
        link={"https://www.desmos.com/calculator/gulcaggspf"}>Here</Link> you
      can see my first attempt at a perspective projection in Desmos.
    </p>
    <br/>
    <Subtitle>
      Getting over the quirks of desmos
    </Subtitle>
    <p>
      To get to this point, I had to jump over so many hurdles. For one, Desmos
      doesn't support lists of lists, which makes doing any operation that
      requires multiple positions clumsy. The best way I've come up with to
      mitigate this is to store one list for each component of the calculation.
    </p>
    <br/>
    <p>
      The lack of lists of lists also makes representing matrices annoying. I
      settled on flattening out the list and using the
      formula <InlineCode>matrix[row * width + column]</InlineCode> to get the
      value of the cell.
    </p>
    <br/>
    <Subtitle>
      The issue with my first attempt
    </Subtitle>
    <p>
      Well, as it turns out, through all my years of graphics programming up to
      that point, I had never actually learned how the perspective matrix
      worked! The whole thing was a really humbling experience for me and it got
      me to read up more on the math, hidden behind wrappers, that I've been
      using so often.
    </p>
    <br/>
    <p>
      The missing step was the divide by w, which is integral to getting the
      perspective distortion that brings objects closer to the center of the
      screen the farther they are away. The w value is set to the actual z
      value,
      whereas the z value is mapped from the interval [near, far] to [0, 1],
      which is why my previous attempt that divided by z didn't look correct.
    </p>
    <br/>
    <Subtitle>
      Loading 3D models
    </Subtitle>
    <p>
      The next hurdle was getting 3D models from Blender
      into the graphing calculator. For this, I used a separate program in C
      that used Assimp to parse files and spit out various lists ready to
      copy and paste into Desmos.
    </p>
    <br/>
    <p>
      Alright, I've got the data now. Time to render it! Because I used the
      vertex/index
      buffer format that OpenGL uses, it was simple to get all the polygons
      rendered
      like so.
    </p>
    <br/>
    <CodeBlock lang={"desmodder script"}>
      polygon(<br/>
      &nbsp;&nbsp;f_transform(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;M_world2ndc,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;x_vbo[i_vtx0],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;y_vbo[i_vtx0],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;z_vbo[i_vtx0]<br/>
      &nbsp;&nbsp;),<br/>
      &nbsp;&nbsp;f_transform(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;M_world2ndc,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;x_vbo[i_vtx1],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;y_vbo[i_vtx1],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;z_vbo[i_vtx1]<br/>
      &nbsp;&nbsp;),<br/>
      &nbsp;&nbsp;f_transform(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;M_world2ndc,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;x_vbo[i_vtx2],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;y_vbo[i_vtx2],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;z_vbo[i_vtx2]<br/>
      &nbsp;&nbsp;)<br/>
      )
    </CodeBlock>
    <br/>
    <p>
      Great! We've got a working renderer now.
    </p>
    <br/>
    <div className={"w-full flex flex-row place-content-center"}>
      <img src={cube}
           alt={"cube without depth sorting. the back faces are in front!"}
           className={"w-2/3"}></img>
    </div>
    <br/>
    <p>
      Oh.
    </p>
    <br/>
    <Subtitle>
      Implementing depth sorting
    </Subtitle>
    <p>
      Well, it's time to implement depth sorting! I'll describe my (slow) first
      attempt in detail here, and later on I'll go over optimizations. The main
      idea is this: if we draw the triangles in order from front to back, then
      the triangles in the back will be occluded by the triangles in front, like
      they're supposed to!
    </p>
    <br/>
    <p>
      In order to implement this, I used the centroid of the triangle as its
      position and projected each of them to use its z position in NDC as its
      depth.
    </p>
    <br/>
    <CodeBlock lang={"desmodder script"}>
      t_centroidX = <br/>
      &nbsp;&nbsp;(x_vbo[i_idx0] + x_vbo[i_idx1] + x_vbo[i_idx2]) / 3<br/><br/>
      t_centroidY = <br/>
      &nbsp;&nbsp;(y_vbo[i_idx0] + y_vbo[i_idx1] + y_vbo[i_idx2]) / 3<br/><br/>
      t_centroidZ = <br/>
      &nbsp;&nbsp;(z_vbo[i_idx0] + z_vbo[i_idx1] + z_vbo[i_idx2]) / 3<br/><br/>
      t_depths = -f_z3(T, t_centroidX, t_centroidY, t_centroidZ)<br/>
    </CodeBlock>
    <br/>
    <p>
      Here's where my first implementation gets a bit dumb. I had to sort the
      indices for each triangle, but keep the indices together.
    </p>
    <br/>
    <p>
      So I did the most straightforward thing!
    </p>
    <br/>
    <CodeBlock lang={"desmodder script"}>
      t_keys = <br/>
      &nbsp;&nbsp;[t_depths[floor(i / 3) + 1] for i in 0..t_depths.length /
      3]<br/><br/>
      i_sorted = sort(i_ibo, t_keys)
    </CodeBlock>
    <br/>
    <p>
      That actually works, but it's slow because it allocates so many lists and
      sorts 3x the data it needs to. Oh well, let's implement lighting now and
      optimize later!
    </p>
    <br/>
    <Subtitle>
      Phong shading
    </Subtitle>
    <p>
      To really cap it all off, I added the phong shading model to the renderer,
      which is a really fast approximation for proper lighting that really sells
      the 3D effect.
    </p>
    <br/>
    <CodeBlock lang={"desmodder script"}>
      t_spec =<br/>
      &nbsp;&nbsp;max(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;f_dot3v(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t_viewDirX / t_viewDirNorm,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t_viewDirY / t_viewDirNorm,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;t_viewDirZ / t_viewDirNorm,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l[1] - 2 * x_norm * t_dotNormIncident,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l[2] - 2 * y_norm * t_dotNormIncident,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;l[3] - 2 * z_norm *
      t_dotNormIncident<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;),<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;0<br/>
      &nbsp;&nbsp;) ^ 32<br/><br/>

      t_l =<br/>
      &nbsp;&nbsp;sort(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;0.1 + max(<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;x_norm * -l[1] + y_norm * -l[2] +
      z_norm * -l[3],<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;0<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;) + t_spec * 0.3,<br/>
      &nbsp;&nbsp;&nbsp;&nbsp;t_depths<br/>
      &nbsp;&nbsp;)<br/>
    </CodeBlock>
    <br/>
    <Subtitle>
      The final result
    </Subtitle>
    <div className={"w-full flex flex-row place-content-center"}>
      <iframe src="https://www.desmos.com/calculator/hvcxvrd18m?embed"
              width="500"
              height="500" className={"border-4 border-black"} frameborder={0}/>
    </div>
  </Post>
)