export function Post(props) {
  return (
    <div id={props.id} className={"w-screen overflow-clip"}>
      {!props.ignoreImage &&
        <img
          className={`h-56 z-10 w-screen ${props.imgCover ? "object-cover" : "object-scale-down"} border-t-4 border-b-4 border-l-0 border-r-0 border-black -mb-4`}
          style={{backgroundColor: props.bgColor}}
          src={props.imgSrc} alt={props.imgAlt}/>
      }
      <div className={"w-screen flex place-content-center sf-ui-reg text-lg"}>
        <div
          className={`2xl:w-1/3 xl:w-2/5 lg:w-1/2 md:w-2/3 w-full p-4 ${!props.ignoreImage ? "mt-4" : ""}`}>
          <div className={"sf-ui-bold text-6xl pb-2"}>
            {props.title}
          </div>
          <div>
            {props.date}
          </div>
          <div className={"pb-6 mt-2 border-t-4 border-black"}/>
          {props.children}
          {props.id !== "welcome" &&
            <>
              <div className={"mb-6 pt-6 mt-2 border-b-4 border-black"}/>
              <p>
                That's the end of this post. Thanks for reading!
                <br/>
                <Link link={`${window.location.hostname}/#welcome`}>Back to
                  top</Link>
              </p>
            </>
          }
          <div className={"pt-2"}/>
        </div>
      </div>
    </div>
  )
}

export function Subtitle(props) {
  return (
    <div>
      <div className={"sf-ui-bold text-3xl pb-2"}>
        {props.children}
      </div>
      <div className={"pb-6 mt-2 border-t-4 border-black"}/>
    </div>
  )
}

export function CodeBlock(props) {
  return (
    <div className={"border-4 border-black"}>
      <div className={"border-b-4 border-black p-2"}>
        {props.lang}
      </div>
      <div className={"p-2 iosevka text-lg"}>
        {props.children}
      </div>
    </div>
  )
}

export function InlineCode(props) {
  return (
    <span className={"bg-slate-200 rounded pl-0.5 pr-0.5 iosevka"}>
      {props.children}
    </span>
  )
}

export function Link(props) {
  let link = props.link
  if (!link.startsWith("htt")) link = "https://" + link;
  return (
    <a
      href={link}
      className={"font-medium text-blue-600 hover:text-blue-500 hover:underline hover:cursor-pointer"}>{props.children}</a>
  )
}